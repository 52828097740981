<template>
  <div>
    <!-- <pre>
      IsVegaPaused {{ isVegaPaused }}
      hasVegaDailyLimit {{ hasVegaDailyLimit }}
      currentVegaDailyLimit {{ currentVegaDailyLimit }} , type: {{ typeof currentVegaDailyLimit }}
      vegaCurrentMonth {{ JSON.stringify(vegaCurrentMonth) }}
      vegaCurrentVisitors {{ vegaCurrentVisitorsForTheDay }}
      vegaTotalCurrentCost {{ vegaTotalCurrentCost }} , type {{ typeof vegaTotalCurrentCost }} {{ vegaTotalCurrentCost2 }}
      $vegaCostPerVisitor {{ $vegaCostPerVisitor }} , type {{ typeof $vegaCostPerVisitor }}
      vegaWillExceedDailyLimit {{ vegaWillExceedDailyLimit }}
    </pre> -->
    <div id="visitor-list-view" class="data-list-container">
      <vs-popup :title="`${$t('vue.deleteAllVisitors')}`" :active.sync="removeAllVisitors">
        <p class="mb-2">
          {{ $t('vue.confirmQuestion') }}
        </p>
        <vs-button @click="deleteAll" color="#e8546f" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
        <vs-button @click="removeAllVisitors = false" type="border" color="#e8546f">{{ $t('vue.cancel') }}</vs-button>
      </vs-popup>

      <vs-prompt
        :title="$t('modals.deleteVisitor')"
        @cancel="onCloseModal"
        @accept="() => deleteData(selectedVisitorId)"
        @close="onCloseModal"
        color="danger"
        :cancel-text="$t('vue.cancel')"
        :accept-text="$t('vue.delete')"
        :active.sync="removeVisitor"
      >
        <div class="con-exemple-prompt">
          {{ $t('modals.deleteVisitorQuestion') }}
        </div>
      </vs-prompt>

      <vs-prompt
        :cancel-text="$t('vue.cancel')"
        :accept-text="$t('vue.save')"
        :title="$t('vue.updateMobile')"
        @accept="updateMobile"
        :active.sync="mobilePrompt"
        :is-valid="validateForm"
      >
        <div class="con-exemple-prompt">
          <vs-input
            :placeholder="$t('inputs.mobile')"
            v-model="mobile"
            v-validate="'required|validatePhoneForRegion|validateMobile'"
            data-vv-validate-on="blur"
            :name="$t('inputs.mobile')"
            class="mt-3 w-full"
          />
          <div class="text-danger text-sm">{{ errors.first($t('inputs.mobile')) }}</div>
        </div>
      </vs-prompt>

      <div class="visitors-container">
        <div :class="!HAS_ACCESS ? 'blur-background' : ''">
          <link rel="stylesheet" type="text/css" media="all" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css" />
          <visitor v-show="showVisitor"></visitor>
          <div class="w-full mb-5" v-show="!checkAddonCompanyClearNames(companyInfo) && b2bEnabled && false">
            <vs-alert class="addon-cc-message" :active.sync="showAddonCompanyClearNameMessage" closable close-icon="close">
              <div v-html="$t('addons.companyClearMessage')"></div>
            </vs-alert>
          </div>

          <div v-if="activeUserInfo.role === 'admin' && !showVisitor" class="ml-6 mb-5 widget-filter">
            <div class="widget-filter-select">
              <multiselect
                v-model="widgets"
                :options="widgetList"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :selectLabel="''"
                :selectedLabel="''"
                :deselectLabel="''"
                :placeholder="$t('vue.selectDialog')"
                label="name"
                track-by="name"
                :preselect-first="false"
                @input="updateSelectedWidgets"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <vs-chip v-if="values.length > 5" v-show="!isOpen" color="primary">{{ values.length }} {{ $t('vue.dialogsSelected') }}</vs-chip>
                  <div v-else>
                    <vs-chip v-for="(widget, index) of values" :key="index" v-show="!isOpen" color="primary">{{ widget.name }}</vs-chip>
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="widget-filter-button">
              <vs-button
                class="widget-filter-button--btn"
                color="primary"
                type="filled"
                size="medium"
                icon-pack="feather"
                icon="icon-filter"
                @click="updateWidgetFilter"
                :disabled="isWidgetSearchDisabled"
              ></vs-button>
            </div>
          </div>

          <vs-table
            v-show="!showVisitor"
            :key="tableKey"
            ref="table"
            :multiple="activeUserInfo.role === 'admin'"
            v-model="selected"
            pagination
            :max-items="itemsPerPage"
            search
            :data="visitorList"
          >
            <div slot="header" class="flex flex-column items-start flex-grow justify-center">
              <div
                class="btn-add-new mb-4 rounded-lg cursor-pointer flex w-full flex-row items-center justify-start font-medium text-base"
                :class="!HAS_B2B_ACCESS ? 'b2b-disabled' : ''"
              >
                <div class="flex justify-center items-center mr-4">
                  <feather-icon icon="BriefcaseIcon" svgClasses="h-4 w-4" />
                  <label class="ml-2 mr-2 heading-label">{{ $t('vue.businessVisitors') }}</label>
                  <vs-switch color="#275D73" v-if="HAS_B2B_ACCESS" v-model="b2bEnabled" :disabled="!HAS_B2B_ACCESS">
                    <span slot="on">{{ $t('vue.on') }}</span>
                    <span slot="off">{{ $t('vue.off') }}</span>
                  </vs-switch>
                  <vx-tooltip v-if="!HAS_B2B_ACCESS" :text="$t('upgrade-messages.locked-feature')" position="top" style="height: 17px">
                    <small-lock-icon />
                  </vx-tooltip>
                  <vx-tooltip :text="$t('info.businessCustomers')" position="top" class="flex ml-1" style="width: 17px">
                    <img width="17" height="17" :src="infoImg" style="display: inline-block" />
                  </vx-tooltip>
                </div>

                <div class="flex justify-center items-center mr-4">
                  <feather-icon icon="RadioIcon" svgClasses="h-4 w-4" />
                  <label class="ml-2 mr-2 heading-label">{{ $t('vue.onlineVisitors') }}</label>
                  <vs-switch color="#275D73" v-model="showOnlineVisitors">
                    <span slot="on">{{ $t('vue.on') }}</span>
                    <span slot="off">{{ $t('vue.off') }}</span>
                  </vs-switch>
                  <vx-tooltip :text="$t('info.currentWebsiteVisitors')" position="top" class="flex ml-1" style="height: 17px">
                    <img width="17" height="17" :src="infoImg" style="display: inline-block" />
                  </vx-tooltip>
                </div>

                <div class="flex justify-center items-center mr-4">
                  <vx-tooltip :text="$t('calendar.name')" class="inline-flex" position="top">
                    <vs-button color="primary" type="border" size="medium" icon-pack="feather" icon="icon-calendar" @click="showDateRange"></vs-button>
                  </vx-tooltip>
                  <date-range-picker
                    class="ml-2"
                    ref="picker"
                    opens="center"
                    :localeData="localeData"
                    :maxDate="maxDate"
                    :showWeekNumbers="false"
                    :showDropdowns="true"
                    :ranges="false"
                    :autoApply="true"
                    v-model="dateRange"
                    :linkedCalendars="true"
                    @update="applyFilter"
                  >
                    <!--    header slot-->
                    <div slot="header" class="slot">
                      <span>{{ $t('vue.chooseDateRange') }}</span>
                    </div>
                    <template v-slot:input="picker"> {{ picker.startDate | date }} - {{ picker.endDate | date }} </template>
                    <!--    footer slot-->
                    <div slot="footer" slot-scope="data" class="slot">
                      <div>
                        <b class="text-black">{{ $t('vue.dateRange') }} </b> {{ data.rangeText && data.rangeText.trim().length > 1 ? data.rangeText : '' }}
                      </div>
                      <div style="margin-left: auto">
                        <a @click="data.clickApply" v-if="!data.in_selection" class="btn btn-primary btn-sm">Choose current</a>
                      </div>
                    </div>
                  </date-range-picker>
                  <vs-button
                    v-if="dateRange.startDate || dateRange.endDate"
                    @click="clearDateFilter"
                    icon-pack="feather"
                    icon="icon-x"
                    type="border"
                    class="ml-2"
                  >
                  </vs-button>
                  <vx-tooltip :text="$t('info.date')" position="top" class="ml-1" style="height: 17px">
                    <img width="17" height="17" :src="infoImg" style="display: inline-block" />
                  </vx-tooltip>
                </div>

                <!-- ITEMS PER PAGE -->
                <div class="flex justify-center items-center mr-4">
                  <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
                    <div
                      class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                      style="border-radius: 5px"
                    >
                      <span class="mr-2">
                        {{ firstRecordNumber }} - {{ lastRecordNumber }} {{ $t('vue.of') }}
                        {{ queriedItems }}
                      </span>
                      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="updateItemsPerPage(4)">
                        <span>4</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="updateItemsPerPage(10)">
                        <span>10</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="updateItemsPerPage(15)">
                        <span>15</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="updateItemsPerPage(20)">
                        <span>20</span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
            </div>

            <template slot="thead">
              <vs-th>{{ $t('vue.online') }}</vs-th>
              <vs-th sort-key="country">{{ $t('vue.location') }}</vs-th>
              <vs-th sort-key="companyName">{{ $t('vue.connection') }}</vs-th>
              <vs-th sort-key="dialogId">{{ $t('vue.widgetName') }}</vs-th>
              <vs-th sort-key="scoring">{{ $t('vue.buyingIntent') }}</vs-th>

              <vs-th sort-key="scoring">{{ $t('vue.status') }}</vs-th>

              <!-- <vs-th>{{ $t('vue.device') }}</vs-th> -->
              <!-- <vs-th sort-key="popularity">{{ $t('vue.scoring') }}</vs-th> -->
              <!-- <vs-th>{{ $t('vue.online') }}</vs-th> -->
              <vs-th>{{ $t('vue.action') }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div
                      class="visitor-list-view--online-indicator"
                      :data-id="tr.id"
                      :class="{ 'visitor-list-view--online-indicator--offline': !tr.online }"
                    ></div>
                  </vs-td>

                  <vs-td class="visitor-list-view--column">
                    <div class="visitor-list-view--column--multiple">
                      <div>
                        <vx-tooltip class="visitor-country" :text="countryName(tr.country)" position="top">
                          <img v-if="tr.country" :alt="countryName(tr.country)" :src="countryImage(tr.country)" />
                        </vx-tooltip>
                      </div>
                      <div>{{ tr.city }}</div>
                    </div>
                  </vs-td>

                  <vs-td v-if="!b2bEnabled" class="visitor-list-view--column about">
                    <p v-if="tr.b2b" class="product-name font-medium visitor-list-view--wrap">
                      {{ !HAS_B2B_ACCESS ? $t('vue.businessConnection') : tr.companyName }}
                    </p>
                    <p v-else class="product-name font-medium visitor-list-view--wrap">{{ $t('vue.privateConnection') }}</p>
                  </vs-td>

                  <vs-td v-if="b2bEnabled" class="visitor-list-view--column about">
                    <p v-if="tr.b2b" class="product-name font-medium visitor-list-view--wrap">
                      <span v-if="checkAddonCompanyClearNames(companyInfo)">
                        {{ !HAS_B2B_ACCESS ? $t('vue.businessConnection') : tr.companyName }}
                      </span>
                      <span v-else-if="companyInfo && companyInfo.paymentStatus === 'trial'" :class="{ blurtext: showInfo(tr.itemNumber) }">
                        {{ showInfo(tr.itemNumber) ? tr.companyName : tr.companyName | shuffle }}
                      </span>
                      <span v-else-if="companyInfo && companyInfo.paymentStatus === 'expired'" class="blurtext">{{ tr.companyName | shuffle }}</span>
                      <span v-else>{{ tr.companyName }}</span>
                    </p>
                    <p v-else class="product-name font-medium visitor-list-view--wrap">{{ $t('vue.privateConnection') }}</p>
                  </vs-td>

                  <vs-td class="visitor-list-view--column visitor-list-view--wrap">
                    {{ tr.widgetName }}
                  </vs-td>

                  <vs-td class="visitor-list-view--column">
                    <VisitorScoring :score="tr.score" :online="tr.online" :isVegaWidgetEnabled="tr.isVegaWidgetEnabled" />
                  </vs-td>

                  <vs-td class="visitor-list-view--column">
                    <VisitorStatus :score="tr.score" :online="tr.online" :key="tr.id" :isVegaWidgetEnabled="tr.isVegaWidgetEnabled" />
                  </vs-td>

                  <!--

                  <vs-td>
                    <p class="product-name font-medium truncate">
                      <vx-tooltip :text="removeQueryParams(tr.location)">
                        <div class="domain-name">{{ extractDomain(tr.location) }}</div>
                      </vx-tooltip>
                    </p>
                  </vs-td>

                  <vs-td class="device">
                    <vx-tooltip :text="getDeviceInfo(tr.userAgent).tooltip" class="tooltip-useragent" position="top" style="height: 17px; max-width: 500px">
                      <p class="product-category">
                        <span v-html="getDeviceInfo(tr.userAgent) ? getDeviceInfo(tr.userAgent).browser.html : null" />
                        <span class="mx-3" v-html="getDeviceInfo(tr.userAgent) ? getDeviceInfo(tr.userAgent).os.html : null" />
                        <span v-html="getDeviceInfo(tr.userAgent) ? getDeviceInfo(tr.userAgent).platform.html : null" />
                      </p>
                    </vx-tooltip>
                  </vs-td>

                  <vs-td class="scoring">
                     <vs-progress :percent="Number(tr.scoring)" :color="getPopularityColor(Number(tr.scoring))" class="shadow-md" />
                  </vs-td>


                  <vs-td class="online">
                    <vs-button :disabled="disable ? true : false" v-if="tr.surlfyURL && tr.connectedAgent" color="#008000" type="filled" size="small"
                      >{{ $t('vue.inCallWith') }} {{ tr.connectedAgent }}</vs-button
                    >
                    <div v-else-if="!tr.online" class="text-xs">{{ tr.lastSeen }}</div>
                    <div v-else-if="tr.online" class="text-xs">{{ $t('vue.online').toLowerCase() }}</div>
                  </vs-td>

                   -->

                  <vs-td class="whitespace-no-wrap visitor-list-view--column">
                    <div class="visitor-list-view--action">
                      <VisitorAction
                        :online="tr.online"
                        :isCallStarted="tr.isCallStarted"
                        :isVegaActivated="isVegaActivated"
                        :isVegaWidgetEnabled="tr.isVegaWidgetEnabled"
                        @initiate-call="agentInitiatesCall(tr.id)"
                      />
                      <div
                        class="visitor-list-view--action visitor-list-view--info"
                        :class="{ 'visitor-list-view--info--disabled': tr.b2b && !HAS_B2B_ACCESS }"
                        @click.stop="viewData(tr.id, tr.b2b)"
                      >
                        <vs-icon
                          icon="icon-info"
                          icon-pack="feather"
                          size="small"
                          :color="tr.b2b && !HAS_B2B_ACCESS ? 'rgba(98, 98, 98, 0.4)' : 'rgb(98, 98, 98)'"
                          style="display: inline-block"
                        ></vs-icon>
                      </div>
                      <div
                        class="visitor-list-view--action visitor-list-view--delete"
                        :class="{ 'visitor-list-view--delete--disabled': !checkAddonCompanyClearNames(companyInfo) && b2bEnabled && showInfo(tr.itemNumber) }"
                        @click.stop="deleteVisitor(tr.id, tr.itemNumber)"
                      >
                        <vs-icon
                          icon="icon-trash"
                          size="small"
                          icon-pack="feather"
                          :color="
                            !checkAddonCompanyClearNames(companyInfo) && b2bEnabled && showInfo(tr.itemNumber) ? 'rgba(240, 85, 6, 0.4)' : 'rgb(240, 85, 6)'
                          "
                          style="display: inline-block"
                        ></vs-icon>
                      </div>
                    </div>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
        <visitors-message v-if="!HAS_ACCESS"></visitors-message>
      </div>
    </div>
  </div>
</template>

<script>
import Visitor from './Visitor'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import chromeNotifications from '@/assets/utils/chrome-notifications.js'
import _ from 'underscore'
import moment from 'moment'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
const useragent = require('useragent')

import { UAParser } from 'ua-parser-js'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { mapGetters, mapMutations } from 'vuex'
import SmallLockIcon from '@/components/icons/SmallLockIcon.vue'
import VisitorsMessage from '@/components/upgrade-messages/VisitorsMessage.vue'
import VisitorScoring from '@/components/vega/VisitorScoring.vue'
import VisitorStatus from '@/components/vega/VisitorStatus.vue'
import VisitorAction from '@/components/vega/VisitorAction.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Visitor,
    DateRangePicker,
    SmallLockIcon,
    VisitorsMessage,
    Multiselect,
    VisitorScoring,
    VisitorStatus,
    VisitorAction
  },
  data() {
    return {
      demoDataVisitors: require('@/assets/vega_demo_data/visitors_page.json'),
      widgets: [],
      isWidgetSearchDisabled: true,
      removeAllVisitors: false,
      removeVisitor: false,
      selectedVisitorIdId: null,
      isSafari: useragent.is(navigator.userAgent).safari,
      showAddonCompanyClearNameMessage: true,
      companyInfo: null,
      //subscribeListener: null,
      selected: [],
      //visitors: [],
      // products: [],
      itemsPerPage: 4,
      isMounted: false,
      countryCodes: require('@/assets/countryCodes.json'),

      notificationsEnabled: false,
      b2bEnabled: this.$store.state.visitors.b2bEnabled && this.HAS_B2B_ACCESS,
      showOnlineVisitors: this.$store.state.visitors.showOnlineVisitors,
      showVisitor: false,
      tableKey: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      dateFilter: false,
      dateRange: {
        startDate: null,
        endDate: null
      },
      maxDate: new Date(),
      localeData: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: this.daysOfWeek(),
        monthNames: this.monthNames(),
        firstDay: 0
      },
      displayRangePicker: false,
      subscriptions: {
        email: true,
        sms: false,
        push: false
      },
      mobilePrompt: false,
      mobile: '',
      infoImg: require('@/assets/images/elements/info.svg'),
      disable: false,
      endpoints: [],
      addNewDemoItemWIntervalId: null
    }
  },
  watch: {
    '$i18n.locale'() {
      setTimeout(() => {
        if (document.getElementById('upgradeAddonCC')) {
          document.getElementById('upgradeAddonCC').addEventListener('click', this.upgradeAddonCC)
        }
      }, 100)
    },
    'subscriptions.push'() {
      this.subscribePushNotification()
    },
    mobile() {
      let mobile = this.mobile
      mobile = mobile && mobile.replace(/[^\d]/g, '').startsWith('00') ? `+${mobile.replace(/[^\d]/g, '').slice(2)}` : mobile
      if (mobile) {
        mobile = `+${mobile.replace(/[^\d]/g, '')}`
      }
      this.mobile = mobile
    },
    b2bEnabled() {
      this.enableDisableB2B()
    },
    HAS_B2B_ACCESS() {
      this.enableDisableB2B()
    },
    async showOnlineVisitors() {
      this.$db.collection('users').doc(this.activeUserInfo.uid).set({ filterOnlineVisitors: this.showOnlineVisitors }, { merge: true })
      this.$refs.table.currentx = 1
      this.$store.commit('UPDATE_VISITORS_SHOW_ONLINE_VISITORS', this.showOnlineVisitors)
    },
    widgets() {
      this.isWidgetSearchDisabled = false
    },
    widgetList() {
      if (this.widgetList) {
        this.setSelectedWidgetIds()
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      visitors: 'visitors',
      dialogs: 'dialogs',
      dateRangeVisitors: 'dateRange',
      company: 'company'
    }),
    widgetList() {
      return this.myDialogs.map((x) => {
        return {
          name: x.name,
          id: x.id
        }
      })
    },
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('visitors') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.visitorList.length
    },
    HAS_B2B_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('b2b-identification') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    visitorList() {
      if (!this.HAS_ACCESS) {
        return []
      }

      const widgetIds = this.widgets.map((x) => x.id)
      const isEmptySelectedWidgets = widgetIds.length === 0

      if (this.company && this.company.isDemoAccount) {
        let data = this.demoDataVisitors

        if (this.b2bEnabled) {
          data = data.filter((item) => Boolean(item.b2b))
        }

        if (this.showOnlineVisitors) {
          data = data.filter((item) => Boolean(item.online))
        }

        return _.sortBy(data, 'sortOrder', 'modified')
      }

      const websiteVisitors = this.visitors.filter((x) => isEmptySelectedWidgets || widgetIds.includes(x.dialogId))

      // websiteVisitors = websiteVisitors.map((_visitor) => {
      //   const widget = this.dialogs && this.dialogs.find((x) => x.id === _visitor.dialogId)
      //   if (widget) {
      //     _visitor.widgetName = widget.name
      //     _visitor.isVegaWidgetEnabled = widget.isVegaWidget && widget.isVegaWidgetEnabled
      //   }
      //   return _visitor
      // })

      return _.sortBy(websiteVisitors, 'sortOrder', 'modified')
    },
    currentPage() {
      if (this.$refs.table) {
        return this.$refs.table.currentx
      }
      return 0
    },
    validateForm() {
      return !this.errors.any() && this.mobile !== ''
    },
    firstRecordNumber() {
      let currentPage = 1
      if (this.$refs.table && this.$refs.table.currentx) {
        currentPage = this.$refs.table.currentx
      }
      if (this.queriedItems === 0) {
        return 0
      }

      const x = (currentPage - 1) * this.itemsPerPage
      return x + 1
    },
    lastRecordNumber() {
      const recordNumber = this.firstRecordNumber + this.itemsPerPage - 1
      return recordNumber < this.queriedItems ? recordNumber : this.queriedItems
    },
    agentName() {
      return this.activeUserInfo.displayName && this.activeUserInfo.displayName.indexOf('@') === -1
        ? this.activeUserInfo.displayName
        : `${this.activeUserInfo.firstname} $${this.activeUserInfo.lastname}`
    }
  },
  methods: {
    ...mapMutations({
      updateWidgetsFilterForVisitor: 'UPDATE_WIDGETS_FILTER_FOR_VISITOR'
    }),

    emulateNewVisitorsInDemoData() {
      const copyDemoData = JSON.parse(JSON.stringify(this.demoDataVisitors))

      this.addNewDemoItemWIntervalId = setInterval(() => {
        if (this.company && this.company.isDemoAccount) {
          const item = copyDemoData[Math.floor(Math.random() * copyDemoData.length)]
          item.online = true
          item.sortOrder = 0

          this.demoDataVisitors.unshift(item)
        }
      }, 2500)
    },

    setSelectedWidgetIds() {
      if (this.activeUserInfo.selectedWidgetIdsOnVisitorsPage) {
        this.widgets = this.activeUserInfo.selectedWidgetIdsOnVisitorsPage.map((item) => {
          const myDiloag = this.widgetList.find((myDialog) => myDialog.id === item)

          if (!myDiloag) {
            return item
          }

          return {
            name: myDiloag.name,
            id: myDiloag.id
          }
        })
      }
    },

    async updateSelectedWidgets() {
      const selectedWidgetIds = this.widgets.map((item) => item.id)

      this.$db.collection('users').doc(this.activeUserInfo.uid).set({ selectedWidgetIdsOnVisitorsPage: selectedWidgetIds }, { merge: true })
    },
    async agentInitiatesCall(visitorId) {
      const visitor = this.visitorList.find((item) => item.id === visitorId)

      if (!visitor || !visitor.online || !this.isVegaActivated) {
        return
      }
      try {
        await this.$vs.loading()
        const getTurnServers = this.$functions.httpsCallable('getturnservers')
        await getTurnServers({ visitorId: visitor.id })

        const _visitor = {
          agentRequest: true,
          audioPlayed: true,
          requestFrom: 'dialog',
          agentRequestType: 'chat',
          hasSentAutomatedResponse: false,
          modified: dayjs().utc().toDate(),
          ttlDate: dayjs().utc().add(6, 'month').toDate(),
          lastRequestDate: dayjs().utc().toDate(),
          shouldNotifyAgents: true,
          connectedAgentId: null,
          connectedAgent: null,
          callCenterId: this.$firebase.firestore.FieldValue.delete(),
          callCenterName: this.$firebase.firestore.FieldValue.delete(),
          reworkData: null,
          callType: 'incoming-request',
          callTypeState: 'incoming',
          start_time: dayjs().utc().toDate(),
          dialogId: visitor.dialogId,
          abTestId: visitor.abTestId,
          locale: this.$i18n.locale,
          agentsToBeNotified: [this.activeUserInfo.uid],
          sessionId: Math.random().toString(36).substring(2, 15),
          disconnect: this.$firebase.firestore.FieldValue.delete(),
          end_time: this.$firebase.firestore.FieldValue.delete(),
          initiatedBy: 'agent',
          vegaSessionId: this.$firebase.firestore.FieldValue.delete()
        }

        await this.$db.collection('visitors').doc(visitor.id).set(_visitor, { merge: true })

        setTimeout(async () => {
          this.$router.push({ name: 'incoming-requests', params: { id: visitor.id, action: 'accept-call' } })
          await this.$vs.loading.close()
        }, 500)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }
    },
    getDeviceInfo(userAgent) {
      try {
        const { browser, os, device } = UAParser(userAgent)

        // Get browser info
        let browserName = ''
        let browserStyle = ''
        let browserHtml = ''

        if (browser.name && browser.name.toLowerCase().includes('chrome')) {
          browserName = 'Chrome'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-chrome"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('firefox')) {
          browserName = 'Firefox'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-firefox"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('safari')) {
          browserName = 'Safari'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-safari"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('edge')) {
          browserName = 'Edge'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-edge"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('internet explorer')) {
          browserName = 'Internet Explorer'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-internet-explorer"></i>'
        } else if (browser.name && browser.name.toLowerCase().includes('opera')) {
          browserName = 'Opera'
          browserStyle = 'fab'
          browserHtml = '<i class="fab fa-opera"></i>'
        } else {
          browserName = browser.name || 'Unknown'
          browserStyle = 'fas'
          browserHtml = '<i class="fas fa-question"></i>'
        }

        // Get OS info
        let osName = ''
        let osStyle = ''
        let osHtml = ''

        if (os.name && os.name.toLowerCase().includes('windows')) {
          osName = 'Windows'
          osStyle = 'fab'
          osHtml = '<i class="fab fa-windows"></i>'
        } else if (os.name && os.name.toLowerCase().includes('macos')) {
          osName = 'macOS'
          osStyle = 'fab'
          osHtml = '<i class="fab fa-apple"></i>'
        } else if (os.name && os.name.toLowerCase().includes('ios')) {
          osName = 'iOS'
          osStyle = 'fab'
          osHtml = '<i class="fab fa-apple"></i>'
        } else if (os.name && os.name.toLowerCase().includes('android')) {
          osName = 'Android'
          osStyle = 'fab'
          osHtml = '<i class="fab fa-android"></i>'
        } else {
          osName = os.name || 'Unknown'
          osStyle = 'fas'
          osHtml = '<i class="fas fa-desktop"></i>'
        }

        // Get platform info
        let platformName = ''
        let platformStyle = ''
        let platformHtml = ''

        if (device.type === 'mobile') {
          platformName = 'Mobile'
          platformStyle = 'fas'
          platformHtml = '<i class="fas fa-mobile"></i>'
        } else if (device.type === 'tablet') {
          platformName = 'Tablet'
          platformStyle = 'fas'
          platformHtml = '<i class="fas fa-tablet"></i>'
        } else {
          platformName = 'Desktop'
          platformStyle = 'fas'
          platformHtml = '<i class="fas fa-desktop"></i>'
        }

        // Return device info object
        return {
          browser: {
            name: browserName,
            style: browserStyle,
            html: browserHtml
          },
          os: {
            name: osName,
            style: osStyle,
            html: osHtml
          },
          platform: {
            name: platformName,
            style: platformStyle,
            html: platformHtml
          },
          tooltip: `Browser: ${browserName}, OS: ${osName}, Platform : ${platformName}`
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        return null
      }
    },
    updateWidgetFilter() {
      this.isWidgetSearchDisabled = true
      this.updateWidgetsFilterForVisitor(this.widgets.map((x) => x.id))
    },
    extractDomain(url) {
      // Remove the protocol (http:// or https://) from the beginning of the URL
      url = url.replace(/^(https?:\/\/)?(www\.)?/, '')

      // Extract the domain name by getting everything before the next slash
      const domain = url.split('/')[0]

      // Return the domain name
      return domain
    },
    removeQueryParams(url) {
      const urlParts = url.split('?')
      return urlParts[0]
    },
    async enableDisableB2B() {
      const b2bEnabled = this.b2bEnabled && this.HAS_B2B_ACCESS
      await this.$db
        .collection('users')
        .doc(this.activeUserInfo.uid)
        .set({ filterB2B: b2bEnabled || false }, { merge: true })
      this.$refs.table.currentx = 1
      this.$store.commit('UPDATE_VISITORS_B2B_ENABLED', b2bEnabled)
      this.showAddonCompanyClearNameMessage = true
      if (b2bEnabled) {
        setTimeout(() => {
          if (document.getElementById('upgradeAddonCC')) {
            document.getElementById('upgradeAddonCC').addEventListener('click', this.upgradeAddonCC)
          }
        }, 100)
      }
    },
    onCloseModal() {
      this.removeVisitor = false
      this.selectedVisitorId = null
    },
    deleteVisitor(id, itemNumber) {
      if (!this.checkAddonCompanyClearNames(this.companyInfo) && this.b2bEnabled && this.showInfo(itemNumber)) {
        return
      }
      this.selectedVisitorId = id
      this.removeVisitor = true
    },
    updateItemsPerPage(page) {
      this.$refs.table.currentx = 1
      this.itemsPerPage = page
    },
    showInfo(itemNumber) {
      if (this.companyInfo && this.companyInfo.maxVisitorCount !== undefined) {
        return this.companyInfo.maxVisitorCount < itemNumber
      }
      if (this.companyInfo && this.companyInfo.expiryDate !== undefined) {
        return itemNumber > 3
      }
      return false
    },
    checkAddonCompanyClearNames(companyInfo) {
      if (!companyInfo || !companyInfo.addons || !companyInfo.addons.filter((x) => x.name === 'company-clear-names').length === 0) {
        return false
      }
      const addon = companyInfo.addons.filter((x) => x.name === 'company-clear-names')[0]
      if (!addon || !addon.expiryDate) {
        return false
      }
      const expiryDate = dayjs.unix(addon.expiryDate.seconds).tz(this.$defaultTimezone)
      const currentDate = dayjs().tz(this.$defaultTimezone)
      const daysLeft = expiryDate.diff(currentDate, 'day')
      return daysLeft >= 0
    },
    upgradeAddonCC() {
      /* Feature to be implemented */
    },
    clearTooltips() {
      const tooltips = document.getElementsByClassName('vs-tooltip') //divsToHide is an array
      for (let i = 0; i < tooltips.length; i++) {
        tooltips[i].remove()
      }
    },
    updateMobile() {
      const userRef = this.$db.collection('users').doc(this.activeUserInfo.uid)
      this.subscriptions.sms = true
      userRef.set({ mobile: this.mobile, subscriptions: this.subscriptions }, { merge: true })
      this.$store.commit('UPDATE_USER_INFO', { mobile: this.mobile, subscriptions: { ...this.subscriptions } })
    },
    async updateSubscription() {
      const userRef = this.$db.collection('users').doc(this.activeUserInfo.uid)
      if (this.subscriptions.sms && !this.activeUserInfo.mobile) {
        this.subscriptions.sms = false
        this.mobilePrompt = true
      }
      userRef.set({ subscriptions: this.subscriptions }, { merge: true })
      this.$store.commit('UPDATE_USER_INFO', { subscriptions: { ...this.subscriptions } })
    },
    clearDateFilter() {
      this.displayRangePicker = false
      this.dateRange = {}
      this.$store.commit('UPDATE_VISITORS_DATE_RANGE', { startDate: null, endDate: null })
      this.$refs.picker.togglePicker(null, true)
    },
    showDateRange() {
      this.displayRangePicker = true
      setTimeout(() => {
        this.$refs.picker.togglePicker(null, true)
      }, 100)
    },
    capitalizeFirstLetter(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    },
    daysOfWeek() {
      return [
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.sun')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.mon')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.tue')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.wed')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.thu')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.fri')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.sat'))
      ]
    },
    monthNames() {
      return [
        this.$i18n.t('system.months.short.jan'),
        this.$i18n.t('system.months.short.feb'),
        this.$i18n.t('system.months.short.mar'),
        this.$i18n.t('system.months.short.apr'),
        this.$i18n.t('system.months.short.may'),
        this.$i18n.t('system.months.short.jun'),
        this.$i18n.t('system.months.short.jul'),
        this.$i18n.t('system.months.short.aug'),
        this.$i18n.t('system.months.short.sep'),
        this.$i18n.t('system.months.short.oct'),
        this.$i18n.t('system.months.short.nov'),
        this.$i18n.t('system.months.short.dec')
      ]
    },
    applyFilter() {
      this.displayRangePicker = false
      this.$store.commit('UPDATE_VISITORS_DATE_RANGE', this.dateRange)
      this.$refs.table.currentx = 1
    },
    countryName(countryCode) {
      const countryCodes = this.countryCodes.filter((country) => country.code === (countryCode ? countryCode.toLowerCase() : ''))
      return countryCodes && countryCodes.length > 0 ? countryCodes[0].countryName : 'Unknown'
    },
    countryImage(countryCode) {
      const countryCodes = this.countryCodes.filter((country) => country.code === (countryCode ? countryCode.toLowerCase() : ''))
      if (countryCodes && countryCodes.length > 0) {
        return `https://storage.googleapis.com/pathadvice-app.appspot.com/flags/${countryCode}.svg`
      }
      return 'https://storage.googleapis.com/pathadvice-app.appspot.com/flags/unknown.svg'
    },
    async deleteAll() {
      this.$refs.table.queriedResults.forEach(async (item) => {
        await this.deleteData(item.id)
      })
      this.removeAllVisitors = false
    },
    getStatusColor(online) {
      return online ? 'rgb(91, 210, 16)' : '#efefef'
    },
    async deleteData(id) {
      this.$db.collection('visitors').doc(id).delete()
      this.removeVisitor = false
    },
    async promptDialog(id) {
      const ref = firebase.database().ref(`/status/${id}`)
      const event = {}
      event.dialog = new Date()
      event.userId = this.activeUserInfo.uid
      if (this.activeUserInfo.photoURL) {
        event.imageURL = this.activeUserInfo.photoURL
      }
      event.appearance = 'approval'
      await ref.update(event)
      const visitorRefFirestore = await this.$db.collection('visitors').doc(id)
      visitorRefFirestore.set({ dialog: true }, { merge: true })
    },
    viewData(visitorId, b2b) {
      if (!this.HAS_B2B_ACCESS && b2b) {
        return
      }

      const visitor = this.visitorList.find((item) => item.id === visitorId)
      this.showVisitor = true
      this.$serverBus.$emit('get-company-info', visitor)
    },
    getOrderStatusColor(status) {
      if (status === 'on_hold') return 'warning'
      if (status === 'delivered') return 'success'
      if (status === 'canceled') return 'danger'
      return 'primary'
    },
    getPopularityColor(num) {
      //return 'primary';
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    removePreviousSubscription() {
      /*
          Check if subscription exists in localstorage. If it exists in localstorage, check if it exists in the endpoints array.
          If it  exists in endpoints array then remove it.
      */
      if (localStorage.getItem('pa-push-notification')) {
        const endpoint = JSON.parse(localStorage.getItem('pa-push-notification'))
        this.endpoints = this.endpoints.filter((x) => x.id !== endpoint.id)
      }

      /*
          Check if total items in array is equal or greater than 10.
          If there are 10 items then remove the last one.
     */
      if (this.endpoints.length >= 10) {
        this.endpoints.pop()
      }
    },
    async subscribePushNotification() {
      if (this.activeUserInfo && !this.activeUserInfo.impersonated) {
        if (this.subscriptions.push) {
          chromeNotifications.checkSupported()
          const subData = await chromeNotifications.subscribeUserToPush()
          if (subData) {
            this.removePreviousSubscription()

            const endpoint = {
              id: window.btoa(JSON.stringify(subData)),
              subscription: JSON.stringify(subData)
            }
            /* Add subscription to the front of the array */
            this.endpoints.unshift(endpoint)

            await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ endpoints: this.endpoints }, { merge: true })

            localStorage.setItem('pa-push-notification', JSON.stringify(endpoint))
          }
        } else {
          chromeNotifications.unsubscribeUser()
          this.removePreviousSubscription()
          localStorage.removeItem('pa-push-notification')
          await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ endpoints: this.endpoints }, { merge: true })
        }
      }
    },
    async checkPushNotifications() {
      const userRef = await this.$db.collection('users').doc(this.activeUserInfo.uid).get()
      if (userRef.data().subscriptions) {
        this.subscriptions.push = !!userRef.data().subscriptions.push
        this.subscriptions.email = !!userRef.data().subscriptions.email
      }
    }
  },
  created() {
    this.checkPushNotifications()
    if (!this.activeUserInfo.company) {
      return
    }
    if (this.activeUserInfo && this.activeUserInfo.subscriptions) {
      this.subscriptions = { ...this.activeUserInfo.subscriptions }
    }
    if (
      this.company &&
      this.company.paymentStatus &&
      (!this.company.expiryDate || (this.company.expiryDate && moment(this.company.expiryDate).format('MM/DD/yyyy') < moment().format('MM/DD/yyyy'))) &&
      (this.company.paymentStatus === 'expired' || this.company.paymentStatus === 'deleted')
    ) {
      this.disable = true
    }
    this.mobile = this.activeUserInfo.mobile
  },
  async mounted() {
    this.b2bEnabled = this.$store.state.visitors.b2bEnabled
    this.showOnlineVisitors = this.$store.state.visitors.showOnlineVisitors

    if (this.widgetList) {
      this.setSelectedWidgetIds()
    }

    this.$vs.loading()
    const vm = this
    vm.isMounted = true
    this.$refs.table.currentx = 1
    vm.dateRange = vm.dateRangeVisitors

    vm.$serverBus.$on('close-company-info', () => {
      vm.showVisitor = false
    })

    setTimeout(() => {
      if (document.getElementById('upgradeAddonCC')) {
        document.getElementById('upgradeAddonCC').addEventListener('click', vm.upgradeAddonCC)
      }
      this.$vs.loading.close()
    }, 100)


    this.emulateNewVisitorsInDemoData()
  },
  beforeDestroy() {
    this.$serverBus.$off('close-company-info')

    clearInterval(this.addNewDemoItemWIntervalId)
    this.addNewDemoItemWIntervalId = null
  }
}
</script>

<style lang="scss">
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 1s linear;
}

.fade-leave-to {
  transition: all 1s linear;
  opacity: 0;
}

.fade-move {
  transition: all 1s linear;
}

.fade-leave-active {
  position: absolute;
}

.domain-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.devices {
  min-width: 105px;
}

.vs-tooltip {
  width: fit-content !important;
  max-width: 350px;
  padding: 10px;
  div {
    width: fit-content;
  }
}
.w-80 {
  width: 80%;
}

.widget-filter {
  display: flex;

  &-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 250px;
  }

  &-button {
    flex: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    &--btn {
      margin-left: 10px;
      height: 42px !important;
      width: 42px !important;
    }
  }
}

.multiselect__select {
  padding: 8px 8px !important;
}

.multiselect__tags {
  border: none;
}

.multiselect--active {
  z-index: 999999999;
}

.multiselect__option--highlight {
  background: #3034f7;
}

.multiselect__option--selected {
  background: #38a7f9;
  color: white;
}

.blur-background {
  filter: blur(3px);
}

.visitors-container {
  min-height: 300px;
  position: relative;
}

.b2b-disabled {
  .feather-icon {
    color: #c4c4c4;
  }
  label {
    color: #c4c4c4;
  }
  .vs-icon {
    color: rgba(var(--vs-primary), 1);
  }
}
.heading-label {
  font-size: 1rem;
}
.vs-con-dropdown {
  cursor: pointer;
}
.notification-type {
  cursor: pointer;
  .vs-dropdown--custom {
    width: 160px;
    .vs-component {
      margin-bottom: 10px;
    }
  }
}
.openscenter {
  margin-top: -60px;
}
.yearselect {
  width: 75px;
}
.daterangepicker .ranges li.active {
  background-color: rgba(var(--vs-primary), 1) !important;
}
.slot {
  background-color: rgba(var(--vs-primary), 1) !important;
  padding: 0.8rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date-filter {
  min-height: 500px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: rgba(var(--vs-primary), 1) !important;
  border-color: transparent;
}
.visitor-list-view {
  &--online-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #2bff6f;
    &--offline {
      background: #c4c4c4 !important;
    }
  }

  &--wrap {
    text-align: left;
    word-break: break-word;
    font-family: 'Lato';
    word-wrap: break-word;
  }

  &--action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  &--column {
    span {
      display: inline-block;
    }
    &--multiple {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &--info {
    width: 38px;
    height: 38px;
    border: #626262 solid 2px;
    border-radius: 6px;
    margin-left: 10px;

    &--disabled {
      border: rgba(98, 98, 98, 0.4) solid 2px;
    }
  }

  &--delete {
    width: 38px;
    height: 38px;
    border: #f05541 solid 2px;
    border-radius: 6px;
    margin-left: 10px;

    &--disabled {
      border: rgba(240, 85, 65, 0.4) solid 2px;
    }
  }
}
#visitor-list-view {
  .includeIcon {
    display: inline-block;
    margin-left: 5px;
  }
  .device {
    max-width: 120px;
  }
  .about {
    max-width: 350px;
  }
  .scoring {
    max-width: 100px;
  }
  .online {
    max-width: 120px;
  }
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .con-vs-tooltip {
      margin: auto;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;
        max-width: 190px;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          //padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
          box-shadow: none !important;
          font-size: 14px !important;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
    .vs-image {
      width: 100%;
      height: 100%;
      max-width: 20px;
      max-height: 20px;
    }
    .company-image .con-vs-image {
      border-radius: 0px !important;
    }
    .company-image .vs-image--img {
      background-size: cover;
      border-radius: 0px !important;
      min-width: 10px;
    }
    .vs-image .con-vs-image {
      overflow: visible;
    }
    .vs-image--img {
      background: no-repeat;
    }
  }
  .con-vs-tooltip {
    padding-left: 5px;
  }
  .visitor-country {
    img {
      height: 20px;
      width: 20px;
    }
  }

  .blurtext {
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .addon-cc-message {
    padding: 15px;
    height: auto !important;
  }
  .addon-cc-message p {
    line-height: 30px;
  }
  .primary-addon-button {
    padding: 10px;
    background-color: rgba(var(--vs-primary), 1) !important;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .mw-255 {
    max-width: 250px;
  }
}
</style>
